import { type Shadows, createTheme } from '@mui/material/styles';
import palette from './figma/pallete';
import typography from './figma/typography';
import effects from './figma/effects';

import {
    TypographyOptions,
    TypographyStyleOptions,
    Variant,
} from '@mui/material/styles/createTypography';

interface FigmaTypographySettings {
    [index: string]: TypographyStyleOptions;
}

const figmaTypography = typography as FigmaTypographySettings;
const defaultFontSize = 16;

const toVariableLora = (font: TypographyStyleOptions): TypographyStyleOptions => {
    return { ...font, ...{ fontFamily: font.fontFamily?.replace('Lora', 'Lora Variable') } };
};

const toRem = (font: TypographyStyleOptions): TypographyStyleOptions => {
    return {
        ...font,
        ...{
            fontSize:
                typeof font.fontSize === 'number'
                    ? font.fontSize / defaultFontSize + 'rem'
                    : font.fontSize,
        },
    };
};

const responsiveTypography = (tag: Variant): TypographyStyleOptions => {
    return {
        ...toRem(toVariableLora(figmaTypography['typography/base/' + tag])),
        ...{
            '@media (min-width:900px)': toVariableLora(figmaTypography['typography/large/' + tag]),
        },
    };
};

const transformedTypography: TypographyOptions = {
    h1: responsiveTypography('h1'),
    h2: responsiveTypography('h2'),
    h3: responsiveTypography('h3'),
    h4: responsiveTypography('h4'),
    h5: responsiveTypography('h5'),
    h6: responsiveTypography('h6'),
    body1: responsiveTypography('body1'),
    body2: responsiveTypography('body2'),
    subtitle1: responsiveTypography('subtitle1'),
    subtitle2: responsiveTypography('subtitle2'),
    overline: responsiveTypography('overline'),
    caption: responsiveTypography('caption'),
};

const shadows = ['none'].concat(
    Object.values(effects.elevation).filter(e => e !== 'outline')
) as Shadows;

const theme = createTheme({
    palette,
    typography: transformedTypography,
    shadows: shadows,
    components: {
        MuiCssBaseline: {
            //  Scroll-padding prevents the content slipping underneath the AppBar when using #name links.
            //  112px = AppBar height (80px) + Additional padding (32px = 4 x spacing-unit)
            styleOverrides: `
                html {
                    scroll-behavior: smooth;
                    scroll-padding-top: 112px;
                }
            `,
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            }
        }
    },
});

export default theme;
