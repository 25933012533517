const localStorageKey = 'userProfile';
const dismissedMobileDownloadKey = 'dismissedMobileDownload';

export const loadUserLocalStorageProfile = () => {
    try {
        const localStorageValue = window.localStorage.getItem(localStorageKey);
        if (localStorageValue) {
            return JSON.parse(localStorageValue);
        }
    } catch (error) {
        console.error('error loading localStorage', error);
    }
    return {};
};

export const saveUserLocalStorageProfile = userProfile => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(userProfile));
};

export const cleanUserLocalStorageProfile = () => {
    window.localStorage.removeItem(localStorageKey);
};

export const dismissMobileDownloadAlert = () => {
    window.localStorage.setItem(dismissedMobileDownloadKey, 'true');
};

export const isMobileDownloadAlertDismissed = () => {
    const dismissed = window.localStorage.getItem(dismissedMobileDownloadKey);
    return dismissed === 'true';
};
