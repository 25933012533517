const effects = {
    elevation: {
        1: '0px 1px 1px rgba(66, 66, 66, 0.12), 0px 1px 4px rgba(33, 33, 33, 0.14)',
        2: '0px 1px 2px rgba(66, 66, 66, 0.12), 0px 1px 6px rgba(33, 33, 33, 0.14)',
        3: '0px 2px 3px rgba(66, 66, 66, 0.12), 0px 1px 8px rgba(33, 33, 33, 0.14)',
        4: '0px 2px 4px rgba(66, 66, 66, 0.12), 0px 2px 10px rgba(33, 33, 33, 0.14)',
        5: '0px 3px 5px rgba(66, 66, 66, 0.12), 0px 2px 12px rgba(33, 33, 33, 0.14)',
        6: '0px 3px 6px 1px rgba(66, 66, 66, 0.12), 0px 2px 14px rgba(33, 33, 33, 0.14)',
        7: '0px 4px 12px 1px rgba(66, 66, 66, 0.12), 0px 3px 16px 1px rgba(33, 33, 33, 0.14)',
        8: '0px 4px 12px 1px rgba(66, 66, 66, 0.12), 0px 3px 18px 1px rgba(33, 33, 33, 0.14)',
        9: '0px 5px 12px 1px rgba(66, 66, 66, 0.12), 0px 3px 20px 2px rgba(33, 33, 33, 0.14)',
        10: '0px 5px 14px 1px rgba(66, 66, 66, 0.12), 0px 4px 22px 3px rgba(33, 33, 33, 0.14)',
        11: '0px 6px 15px 2px rgba(66, 66, 66, 0.12), 0px 4px 24px 3px rgba(33, 33, 33, 0.14)',
        12: '0px 6px 17px 2px rgba(66, 66, 66, 0.12), 0px 4px 26px 4px rgba(51, 51, 51, 0.14)',
        13: '0px 7px 19px 2px rgba(66, 66, 66, 0.12), 0px 5px 28px 4px rgba(33, 33, 33, 0.14)',
        14: '0px 7px 21px 2px rgba(66, 66, 66, 0.12), 0px 5px 30px 4px rgba(33, 33, 33, 0.14)',
        15: '0px 8px 22px 2px rgba(66, 66, 66, 0.12), 0px 5px 32px 5px rgba(33, 33, 33, 0.14)',
        16: '0px 8px 24px 3px rgba(66, 66, 66, 0.12), 0px 6px 34px 5px rgba(33, 33, 33, 0.14)',
        17: '0px 9px 26px 3px rgba(66, 66, 66, 0.12), 0px 6px 36px 5px rgba(33, 33, 33, 0.14)',
        18: '0px 9px 28px 3px rgba(66, 66, 66, 0.12), 0px 6px 38px 6px rgba(33, 33, 33, 0.14)',
        19: '0px 10px 29px 3px rgba(66, 66, 66, 0.12), 0px 7px 40px 6px rgba(33, 33, 33, 0.14)',
        20: '0px 10px 31px 3px rgba(66, 66, 66, 0.12), 0px 7px 42px 7px rgba(33, 33, 33, 0.14)',
        21: '0px 11px 33px 4px rgba(66, 66, 66, 0.12), 0px 7px 44px 7px rgba(33, 33, 33, 0.14)',
        22: '0px 11px 35px 4px rgba(66, 66, 66, 0.12), 0px 8px 46px 7px rgba(33, 33, 33, 0.14)',
        23: '0px 12px 36px 4px rgba(66, 66, 66, 0.12), 0px 8px 48px 8px rgba(33, 33, 33, 0.14)',
        24: '0px 12px 38px 4px rgba(66, 66, 66, 0.12), 0px 8px 50px 8px rgba(33, 33, 33, 0.14)',
        outlined: '0px 0px 0px 1px rgba(224, 224, 224, 1)',
    },
};

export default effects;
