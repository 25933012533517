import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme/theme';

const enrolledPaths = [
    '/',
    'gaia',
    '/activism-group/',
    '/media-kit',
    '/activism-groups',
    '/partners',
    '/get-involved',
    '/contact',
    '/404',
];

// temporary solution to avoid no enrolled with Gaia when browser auto change the locale
// it's expected in long term all pages will include Gaia then it would not require update supportedLocale array with each new locale
// NOTE: hook useI18next is not loading the current language at this stage then its no possible auto-detect the locale
const supportedLocale = [
    'de',
    'el',
    'en',
    'en_AU',
    'en_CA',
    'en_GB',
    'en_IN',
    'es',
    'fr',
    'fr_CA',
    'ja',
    'pt',
    'pt_BR',
];

export const GaiaProvider = ({ children }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
    </ThemeProvider>
);

export const OptInGaiaProvider = ({ children }) => {
    if (enrolledInDesignSystem(window.location.pathname)) {
        return <GaiaProvider>{children}</GaiaProvider>;
    } else {
        return children;
    }
};

export const enrolledInDesignSystem = path => {
    const pathIncludesLocale = supportedLocale.some(p => path.startsWith(`/${p}/`));
    let pathWithoutLocale = path;
    if (pathIncludesLocale) {
        pathWithoutLocale = path
            .split('/')
            .filter((p, pos) => pos !== 1)
            .join('/');
    }
    return enrolledPaths.some(p => pathWithoutLocale.includes(p));
};
