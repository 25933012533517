// Figma Document Version: 4034128220
const typography = {
    'typography/base/h1': {
        fontSize: 48,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.5px',
        lineHeight: '120.00000476837158%',
    },
    'typography/base/h2': {
        fontSize: 40,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.5px',
        lineHeight: '120.00000476837158%',
    },
    'typography/base/h3': {
        fontSize: 32,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.5px',
        lineHeight: '139.9999976158142%',
    },
    'typography/base/h4': {
        fontSize: 24,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.25px',
        lineHeight: '144.0000057220459%',
    },
    'typography/base/h5': {
        fontSize: 20,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.25px',
        lineHeight: '151.99999809265137%',
    },
    'typography/base/h6': {
        fontSize: 16,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '-0.15000000596046448px',
        lineHeight: '153.99999618530273%',
    },
    'typography/base/body1': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '175%',
    },
    'typography/base/body2': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '142.99999475479126%',
    },
    'typography/base/subtitle1': {
        fontSize: 18,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '153.99999618530273%',
    },
    'typography/base/subtitle2': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.10000000149011612px',
        lineHeight: '157.00000524520874%',
    },
    'typography/base/overline': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '1px',
        lineHeight: '266.00000858306885%',
    },
    'typography/base/caption': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.4000000059604645px',
        lineHeight: '165.9999966621399%',
    },
    'typography/large/h1': {
        fontSize: 64,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.5px',
        lineHeight: '129.99999523162842%',
    },
    'typography/large/h2': {
        fontSize: 48,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.5px',
        lineHeight: '134.0000033378601%',
    },
    'typography/large/h3': {
        fontSize: 32,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.5px',
        lineHeight: '144.0000057220459%',
    },
    'typography/large/h4': {
        fontSize: 24,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.25px',
        lineHeight: '150%',
    },
    'typography/large/h5': {
        fontSize: 20,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.15000000596046448px',
        lineHeight: '151.99999809265137%',
    },
    'typography/large/h6': {
        fontSize: 16,
        fontFamily: 'Lora, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 600,
        letterSpacing: '-0.15000000596046448px',
        lineHeight: '153.99999618530273%',
    },
    'typography/large/body1': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '175%',
    },
    'typography/large/body2': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '142.99999475479126%',
    },
    'typography/large/subtitle1': {
        fontSize: 20,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '150%',
    },
    'typography/large/subtitle2': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.10000000149011612px',
        lineHeight: '157.00000524520874%',
    },
    'typography/large/overline': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '1px',
        lineHeight: '266.00000858306885%',
    },
    'typography/large/caption': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.4000000059604645%',
        lineHeight: '165.9999966621399%',
    },
    'components/alert-title': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '150%',
    },
    'components/alert-description': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '150%',
    },
    'components/avatar-initials': {
        fontSize: 20,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.14000000059604645px',
        lineHeight: '100%',
    },
    'components/badge-label': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.14000000059604645px',
        lineHeight: '163.99999856948853%',
    },
    'components/button-large': {
        fontSize: 15,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.46000000834465027px',
        lineHeight: '175%',
    },
    'components/button-medium': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.4000000059604645px',
        lineHeight: '170.00000476837158%',
    },
    'components/button-small': {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.46000000834465027px',
        lineHeight: '170.00000476837158%',
    },
    'components/input-label': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '100%',
    },
    'components/helper-text': {
        fontSize: 12,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.4000000059604645px',
        lineHeight: '165.9999966621399%',
    },
    'components/input-text': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '150%',
    },
    'components/chip-label': {
        fontSize: 13,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.1599999964237213px',
        lineHeight: '139.9999976158142%',
    },
    'components/tooltip-label': {
        fontSize: 10,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '139.9999976158142%',
    },
    'components/table-header': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.17000000178813934px',
        lineHeight: '170.00000476837158%',
    },
    'components/menu-item': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.15000000596046448px',
        lineHeight: '150%',
    },
    'components/menu-item-dense': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.17000000178813934px',
        lineHeight: '170.00000476837158%',
    },
    'components/list-subheader': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0.10000000149011612px',
        lineHeight: '340.00000953674316%',
    },
    'components/bottom-navigation-active-label': {
        fontSize: 14,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 400,
        letterSpacing: '0.4000000059604645px',
        lineHeight: '165.9999966621399%',
    },
    'components/date-picker-current-month': {
        fontSize: 16,
        fontFamily: 'Roboto, sans-serif',
        paragraphIndent: 0,
        paragraphSpacing: 0,
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '160.0000023841858%',
    },
};
export default typography;
