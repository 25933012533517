// Figma Document Version: 3649492907

const palette = {
    shades: {
        divider: 'rgba(0, 0, 0, 0.12)',
        black: 'rgba(0, 0, 0, 1)',
        white: 'rgba(255, 255, 255, 1)',
    },
    text: {
        primary: 'rgba(0, 0, 0, 0.87)',
        secondary: 'rgba(0, 0, 0, 0.6)',
        disabled: 'rgba(0, 0, 0, 0.38)',
    },
    primary: {
        main: 'rgba(31.2, 52, 173.6, 1)',
        dark: 'rgba(23, 39, 130, 1)',
        light: 'rgba(39, 65, 217, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(31, 52, 174, 0.5)',
        },
    },
    secondary: {
        main: 'rgba(30.4, 146.4, 98.07, 1)',
        dark: 'rgba(23, 110, 74, 1)',
        light: 'rgba(38, 183, 122.58, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(30, 146, 98, 0.5)',
        },
    },
    action: {
        active: 'rgba(0, 0, 0, 0.56)',
        hover: 'rgba(0, 0, 0, 0.04)',
        selected: 'rgba(0, 0, 0, 0.08)',
        disabledbackground: 'rgba(0, 0, 0, 0.12)',
        focus: 'rgba(0, 0, 0, 0.12)',
        disabled: 'rgba(0, 0, 0, 0.38)',
    },
    error: {
        main: 'rgba(211, 47, 47, 1)',
        dark: 'rgba(198, 40, 40, 1)',
        light: 'rgba(239, 83, 80, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(211, 47, 47, 0.5)',
        },
    },
    warning: {
        main: 'rgba(242, 104, 0, 1)',
        dark: 'rgba(233, 75, 0, 1)',
        light: 'rgba(255, 150, 0, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(242, 104, 0, 0.5)',
        },
    },
    info: {
        main: 'rgba(2, 136, 209, 1)',
        dark: 'rgba(1, 87, 155, 1)',
        light: 'rgba(3, 169, 244, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(2, 136, 209, 0.5)',
        },
    },
    success: {
        main: 'rgba(46, 125, 50, 1)',
        dark: 'rgba(27, 94, 32, 1)',
        light: 'rgba(76, 175, 80, 1)',
        contrast: 'rgba(255, 255, 255, 1)',
        states: {
            outlinedborder: 'rgba(46, 125, 50, 0.5)',
        },
    },
    background: {
        default: 'rgba(255, 255, 255, 1)',
        'paper-elevation-0': 'rgba(255, 255, 255, 1)',
        'paper-elevation-1': 'rgba(255, 255, 255, 1)',
        'paper-elevation-2': 'rgba(255, 255, 255, 1)',
        'paper-elevation-3': 'rgba(255, 255, 255, 1)',
        'paper-elevation-4': 'rgba(255, 255, 255, 1)',
        'paper-elevation-5': 'rgba(255, 255, 255, 1)',
        'paper-elevation-6': 'rgba(255, 255, 255, 1)',
        'paper-elevation-7': 'rgba(255, 255, 255, 1)',
        'paper-elevation-8': 'rgba(255, 255, 255, 1)',
        'paper-elevation-9': 'rgba(255, 255, 255, 1)',
        'paper-elevation-10': 'rgba(255, 255, 255, 1)',
        'paper-elevation-11': 'rgba(255, 255, 255, 1)',
        'paper-elevation-12': 'rgba(255, 255, 255, 1)',
        'paper-elevation-13': 'rgba(255, 255, 255, 1)',
        'paper-elevation-14': 'rgba(255, 255, 255, 1)',
        'paper-elevation-15': 'rgba(255, 255, 255, 1)',
        'paper-elevation-16': 'rgba(255, 255, 255, 1)',
        'paper-elevation-17': 'rgba(255, 255, 255, 1)',
        'paper-elevation-18': 'rgba(255, 255, 255, 1)',
        'paper-elevation-19': 'rgba(255, 255, 255, 1)',
        'paper-elevation-20': 'rgba(255, 255, 255, 1)',
        'paper-elevation-21': 'rgba(255, 255, 255, 1)',
        'paper-elevation-22': 'rgba(255, 255, 255, 1)',
        'paper-elevation-23': 'rgba(255, 255, 255, 1)',
        'paper-elevation-24': 'rgba(255, 255, 255, 1)',
    },
    inherit: {
        white: {
            focusvisible: 'rgba(255, 255, 255, 0.3)',
        },
        textprimary: {
            focusvisible: 'rgba(0, 0, 0, 0.3)',
        },
    },
    grey: {
        50: 'rgba(250, 250, 250, 1)',
        100: 'rgba(245, 245, 245, 1)',
        200: 'rgba(238, 238, 238, 1)',
        300: 'rgba(224, 224, 224, 1)',
        400: 'rgba(189, 189, 189, 1)',
        500: 'rgba(158, 158, 158, 1)',
        600: 'rgba(117, 117, 117, 1)',
        700: 'rgba(97, 97, 97, 1)',
        800: 'rgba(66, 66, 66, 1)',
        900: 'rgba(33, 33, 33, 1)',
        main: 'rgba(158, 158, 158, 1)',
        light: 'rgba(238, 238, 238, 1)',
        dark: 'rgba(97, 97, 97, 1)',
    },
    orange: {
        50: 'rgba(255, 243, 222, 1)',
        100: 'rgba(255, 224, 174, 1)',
        200: 'rgba(255, 203, 120, 1)',
        300: 'rgba(255, 182, 64, 1)',
        400: 'rgba(255, 166, 0, 1)',
        500: 'rgba(255, 150, 0, 1)',
        600: 'rgba(252, 138, 0, 1)',
        700: 'rgba(247, 121, 0, 1)',
        800: 'rgba(242, 104, 0, 1)',
        900: 'rgba(233, 75, 0, 1)',
        main: 'rgba(255, 150, 0, 1)',
        light: 'rgba(255, 203, 120, 1)',
        dark: 'rgba(247, 121, 0, 1)',
    },
    green: {
        50: 'rgba(233, 248, 242, 1)',
        100: 'rgba(212, 241, 229, 1)',
        200: 'rgba(168.2, 226.2, 202.03, 1)',
        300: 'rgba(125, 212, 176, 1)',
        400: 'rgba(81.4, 197.4, 149.07, 1)',
        500: 'rgba(38, 183, 122.58, 1)',
        600: 'rgba(30.4, 146.4, 98.07, 1)',
        700: 'rgba(23, 110, 74, 1)',
        800: 'rgba(15.2, 73.2, 49.03, 1)',
        900: 'rgba(11, 55, 37, 1)',
        main: 'rgba(38, 183, 122.58, 1)',
        light: 'rgba(168.2, 226.2, 202.03, 1)',
        dark: 'rgba(23, 110, 74, 1)',
    },
    red: {
        50: 'rgba(255, 235, 240, 1)',
        100: 'rgba(255, 206, 216, 1)',
        200: 'rgba(246, 154, 163, 1)',
        300: 'rgba(240, 113, 126, 1)',
        400: 'rgba(253, 76, 94, 1)',
        500: 'rgba(255, 51, 69, 1)',
        600: 'rgba(246, 41, 68, 1)',
        700: 'rgba(227, 28, 61, 1)',
        800: 'rgba(214, 16, 53, 1)',
        900: 'rgba(200, 0, 41, 1)',
        main: 'rgba(255, 51, 69, 1)',
        light: 'rgba(246, 154, 163, 1)',
        dark: 'rgba(227, 28, 61, 1)',
    },
    purple: {
        50: 'rgba(237, 231, 244, 1)',
        100: 'rgba(209, 197, 229, 1)',
        200: 'rgba(180, 159, 212, 1)',
        300: 'rgba(150, 120, 196, 1)',
        400: 'rgba(128, 91, 182, 1)',
        500: 'rgba(107, 63, 170, 1)',
        600: 'rgba(99, 58, 164, 1)',
        700: 'rgba(87, 50, 155, 1)',
        800: 'rgba(76, 44, 146, 1)',
        900: 'rgba(60, 33, 130, 1)',
        main: 'rgba(107, 63, 170, 1)',
        light: 'rgba(180, 159, 212, 1)',
        dark: 'rgba(87, 50, 155, 1)',
    },
    yellow: {
        50: 'rgba(255, 253, 229, 1)',
        100: 'rgba(255, 249, 191, 1)',
        200: 'rgba(255, 244, 147, 1)',
        300: 'rgba(255, 240, 102, 1)',
        400: 'rgba(253, 235, 61, 1)',
        500: 'rgba(255, 234, 0, 1)',
        600: 'rgba(254, 214, 0, 1)',
        700: 'rgba(254, 189, 0, 1)',
        800: 'rgba(254, 164, 0, 1)',
        900: 'rgba(253, 119, 0, 1)',
        main: 'rgba(255, 234, 0, 1)',
        light: 'rgba(255, 244, 147, 1)',
        dark: 'rgba(254, 189, 0, 1)',
    },
    brown: {
        50: 'rgba(246, 242, 239, 1)',
        100: 'rgba(236, 229, 223, 1)',
        200: 'rgba(217, 204, 191, 1)',
        300: 'rgba(199, 178, 158, 1)',
        400: 'rgba(179.8, 151.8, 126.2, 1)',
        500: 'rgba(161, 126, 94, 1)',
        600: 'rgba(128.8, 100.8, 75.2, 1)',
        700: 'rgba(97, 76, 56, 1)',
        800: 'rgba(64, 51, 38, 1)',
        900: 'rgba(32, 25, 19, 1)',
        main: 'rgba(161, 126, 94, 1)',
        light: 'rgba(217, 204, 191, 1)',
        dark: 'rgba(97, 76, 56, 1)',
    },
    blue: {
        50: 'rgba(233, 236, 251, 1)',
        100: 'rgba(212, 217, 247, 1)',
        200: 'rgba(169, 179.65, 240, 1)',
        300: 'rgba(125, 141, 232, 1)',
        400: 'rgba(82, 103, 225, 1)',
        500: 'rgba(39, 65, 217, 1)',
        600: 'rgba(31.2, 52, 173.6, 1)',
        700: 'rgba(23, 39, 130, 1)',
        800: 'rgba(16, 26.65, 87, 1)',
        900: 'rgba(12, 20, 65, 1)',
        main: 'rgba(39, 65, 217, 1)',
        light: 'rgba(169, 179.65, 240, 1)',
        dark: 'rgba(23, 39, 130, 1)',
    },
    alert: {
        errorcontent: 'rgba(95, 33, 32, 1)',
        errorfill: 'rgba(253, 237, 237, 1)',
        warningcontent: 'rgba(102, 60, 0, 1)',
        warningfill: 'rgba(255, 244, 229, 1)',
        infocontent: 'rgba(1, 67, 97, 1)',
        infofill: 'rgba(229, 246, 253, 1)',
        successcontent: 'rgba(30, 70, 32, 1)',
        successfill: 'rgba(237, 247, 237, 1)',
    },
    appbar: {
        defaultfill: 'rgba(245, 245, 245, 1)',
    },
    avatar: {
        fill: 'rgba(189, 189, 189, 1)',
    },
    backdrop: {
        fill: 'rgba(0, 0, 0, 0.5)',
    },
    breadcrumbs: {
        collapsefill: 'rgba(245, 245, 245, 1)',
    },
    chip: {
        defaultclosefill: 'rgba(0, 0, 0, 1)',
        defaulthoverfill: 'rgba(0, 0, 0, 0.12)',
        defaultfocusfill: 'rgba(255, 255, 255, 0.2)',
        defaultenabledborder: 'rgba(189, 189, 189, 1)',
    },
    input: {
        standard: {
            hoverborder: 'rgba(0, 0, 0, 1)',
        },
        filled: {
            hoverfill: 'rgba(0, 0, 0, 0.09)',
        },
        outlined: {
            hoverborder: 'rgba(0, 0, 0, 1)',
        },
    },
    rating: {
        enabledborder: 'rgba(0, 0, 0, 0.23)',
        activefill: 'rgba(255, 182, 64, 1)',
    },
    switch: {
        knobfill: 'rgba(250, 250, 250, 1)',
        slidefill: 'rgba(0, 0, 0, 1)',
    },
    tooltip: {
        fill: 'rgba(97, 97, 97, 0.9)',
    },
    snackbar: {
        defaultfill: 'rgba(18, 18, 18, 1)',
    },
};
export default palette;
