import React, { useEffect } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut as firebaseSignOut,
    GoogleAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    onAuthStateChanged,
} from 'firebase/auth';
import {
    saveUserFirebaseProfile,
    loadUserFirebaseProfile,
    loadUserMetaData,
    initializeFirebase,
} from './firebase-utils.js';
import {
    loadUserLocalStorageProfile,
    cleanUserLocalStorageProfile,
    saveUserLocalStorageProfile,
} from './localStorage-utils.js';
import { loginReducer } from './login-reducer';

export const FirebaseContext = React.createContext({});

const Provider = props => {
    const [reducerState, reducerDispatch] = React.useReducer(loginReducer, {
        message: undefined,
        error: undefined,
        user: undefined,
        loading: false,
        status: 'non-logged',
        userProfile: {},
        firebaseInstances: {
            app: undefined,
            auth: undefined,
            store: undefined,
        },
    });

    /* Sets up real-time WebSocket connection to Firebase Auth server to handle persistent session */
    useEffect(() => {
        const { auth, store } = initializeFirebase();

        onAuthStateChanged(auth, user => {
            if (user) {
                const updateStoreOnRefresh = async () => {
                    reducerDispatch({
                        type: 'signUpSuccessful',
                        user: user,
                        message: `Welcome ${user.email} to earth hero`,
                    });
                    const userProfile = await loadUserFirebaseProfile(store, user.uid);
                    setUserProfile(userProfile);
                };
                updateStoreOnRefresh();
            }
        });
    }, []);

    const initializeFirebaseCached = () => {
        const firebaseInstancesRedux = reducerState.firebaseInstances;
        if (firebaseInstancesRedux.app === undefined) {
            const firebaseInstances = initializeFirebase();
            reducerDispatch({ type: 'setFirebaseInstances', firebaseInstances: firebaseInstances });
            return firebaseInstances;
        } else {
            return firebaseInstancesRedux;
        }
    };

    const signUp = async (email, password) => {
        const { auth, store } = initializeFirebaseCached();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            reducerDispatch({
                type: 'signUpSuccessful',
                user: user,
                message: `Welcome ${user.email} to earth hero`,
            });
        } catch (e) {
            reducerDispatch({
                type: 'signUpError',
                message: `Error authenticating ${email}: ${error.code} ${error.message}`,
            });
        }
    };

    const signInProcess = async (signInCall, storeDB) => {
        // generic function, wrapper for signInCall
        reducerDispatch({ type: 'signInRequest' });
        let successful = false;
        try {
            const resultAuth = await signInCall();
            const user = resultAuth.user;
            reducerDispatch({
                type: 'signInSuccessful',
                message: `Welcome back ${user.email}`,
                user: user,
            });
            successful = true;
            const userProfile = await loadUserFirebaseProfile(storeDB, user.uid);
            setUserProfile(userProfile);
        } catch (error) {
            reducerDispatch({
                type: 'signInError',
                message: `Error authenticating facebook user: ${error.code} ${error.message}`,
            });
        }
        return successful;
    };
    const signIn = async (email, password) => {
        const { auth, store } = initializeFirebaseCached();
        const test1 = await signInWithEmailAndPassword(auth, email, password);

        return await signInProcess(() => {
            return signInWithEmailAndPassword(auth, email, password);
        }, store);
    };

    const signInWithGoogle = async () => {
        const { auth, store } = initializeFirebaseCached();
        return await signInProcess(() => {
            const provider = new GoogleAuthProvider();
            return signInWithPopup(auth, provider);
        }, store);
    };
    const signInWithFacebook = async () => {
        const { auth, store } = initializeFirebaseCached();
        return await signInProcess(() => {
            const provider = new FacebookAuthProvider();
            return signInWithPopup(auth, provider);
        }, store);
    };

    const signOut = async () => {
        reducerDispatch({ type: 'signOutRequest' });
        const { auth } = initializeFirebaseCached();
        try {
            await firebaseSignOut(auth);
            reducerDispatch({ type: 'signOutSuccessful', message: 'See you soon' });
        } catch (e) {
            reducerDispatch({
                type: 'signOutError',
                message: `Error logging out ${error.message}`,
            });
        }
    };

    const setUserProfile = userProfile =>
        reducerDispatch({ type: 'setUserProfile', userProfile: userProfile });

    const updateUserProfile = (field, value) => {
        reducerDispatch({ type: 'updateUserProfile', field, value });
    };

    const loadUserProfile = async (storeDB, userUuid) => {
        let userProfile;
        if (reducerState.status === 'non-logged' || storeDB === undefined) {
            userProfile = loadUserLocalStorageProfile();
        } else {
            userProfile = await loadUserFirebaseProfile(storeDB, userUuid);
        }
        setUserProfile(userProfile);
    };

    const saveUserProfile = storeDB => {
        if (reducerState.status === 'non-logged' || storeDB === undefined) {
            saveUserLocalStorageProfile(reducerState.userProfile);
        } else {
            // TODO: enable save to firebase once testing is completed for webapp
            // saveUserFirebaseProfile(storeDB, reducerState.user.uuid, reducerState.userProfile);
            saveUserLocalStorageProfile(reducerState.userProfile);
        }
    };

    const cleanUserProfile = () => {
        setUserProfile({});
        cleanUserLocalStorageProfile();
    };

    const getMetaData = async field => {
        const { store } = initializeFirebaseCached();
        const metaData = await loadUserMetaData(store, field);
        return field == 'all' ? metaData : metaData[field];
    };

    return (
        <FirebaseContext.Provider
            value={{
                userProfile: reducerState.userProfile,
                updateUserProfile,
                loadUserProfile,
                saveUserProfile,
                cleanUserProfile,
                getMetaData,
                user: reducerState.user,
                message: reducerState.message,
                error: reducerState.error,
                loading: reducerState.loading,
                status: reducerState.status,
                signUp,
                signIn,
                signOut,
                signInWithGoogle,
                signInWithFacebook,
            }}
        >
            {props.children}
        </FirebaseContext.Provider>
    );
};

export const FirebaseProvider = ({ children }) => <Provider>{children}</Provider>;
