/**
 * API functions to connect with firebase
 */
import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDocs, getDoc, collection, query } from 'firebase/firestore';

export const saveUserFirebaseProfile = (storeDB, userUuid, userProfile) => {
    // TODO
    return true;
};

export const loadUserFirebaseProfile = async (storeDB, userUid) => {
    const q = query(collection(storeDB, `/Users/${userUid}/UserProfile`));
    const querySnapshot = await getDocs(q);
    let userProfile = {};
    querySnapshot.forEach(doc => {
        if (doc.id == userUid) {
            userProfile = doc.data();
        }
    });
    // TODO: process to initialize user, review how user is initialise in the mobile app
    return userProfile;
};

export const loadUserMetaData = async (storeDB, field) => {
    const docRef = doc(storeDB, 'UserMeta', 'AllUsers');
    const docSnap = await getDoc(docRef);
    return docSnap.data();
};

export const initializeFirebase = () => {
    const firebaseConfig = {
        // https://stackoverflow.com/questions/37482366/is-it-safe-to-expose-firebase-apikey-to-the-public
        apiKey: 'AIzaSyD8fBanML8wrqAZJ_SQ3yPS1JoZRNNkl4I',
        authDomain: 'earth-hero.firebaseapp.com',
        databaseURL: 'https://earth-hero.firebaseio.com',
        projectId: 'earth-hero',
        storageBucket: 'earth-hero.appspot.com',
        messagingSenderId: '694482618636',
        appId: '1:694482618636:web:6e097b3b12d6ace2a06129',
        measurementId: 'G-NFB7K2GB6N',
    };
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    const store = getFirestore(app);
    return { app, auth, store };
};
