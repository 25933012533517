export const loginReducer = (state, action) => {
    switch (action.type) {
        case 'setCurrentlySignedInUser':
            if (action.user) {
                return {
                    ...state,
                    user: action.user,
                    status: 'logged',
                };
            }
            return {
                ...state,
                status: 'non-logged',
            };
        case 'signInRequest':
            return {
                ...state,
                message: undefined,
                error: undefined,
                user: undefined,
                loading: true,
            };
        case 'signOutRequest':
            return {
                ...state,
                message: undefined,
                error: undefined,
                loading: true,
            };
        case 'signUpRequest':
            return {
                ...state,
                message: undefined,
                error: undefined,
                user: undefined,
                loading: true,
            };
        case 'signInSuccessful':
            return {
                ...state,
                message: action.message,
                user: action.user,
                status: 'logged',
                error: undefined,
            };
        case 'signOutSuccessful':
            return {
                ...state,
                message: action.message,
                user: undefined,
                status: 'non-logged',
                error: undefined,
                loading: false,
                userProfile: {},
            };
        case 'signUpSuccessful':
            return {
                ...state,
                message: action.message,
                user: action.user,
                status: 'logged',
                error: undefined,
                loading: false,
            };
        case 'signInError':
            return {
                ...state,
                message: undefined,
                status: 'non-logged',
                error: action.message,
                loading: false,
            };
        case 'signOutError':
            return {
                ...state,
                message: undefined,
                error: action.message,
                loading: false,
            };
        case 'signUpError':
            return {
                ...state,
                message: undefined,
                error: action.message,
                loading: false,
            };
        case 'updateUserProfile':
            return {
                ...state,
                userProfile: {
                    ...state.userProfile,
                    [action.field]: action.value,
                },
            };
        case 'setUserProfile':
            return {
                ...state,
                userProfile: action.userProfile,
                loading: false,
                message: undefined,
            };
            break;
        case 'setFirebaseInstances':
            return {
                ...state,
                firebaseInstances: action.firebaseInstances,
            };
            break;
    }
};
